import { toast } from "react-hot-toast";
import BannerError from "./BannerError";

// Only use in client components

const showError = (title, message = "") => {
  // Generate unique id for close button
  const id = `closeBanner${Date.now()}`;

  // Create toast
  const toastId = toast.error(
    <BannerError title={title} message={message} closeId={id} />
  );

  // Add event listener to close toast on click on x
  setTimeout(() => {
    const closeToast = document.getElementById(id);
    closeToast.addEventListener("click", () => {
      toast.dismiss(toastId);
    });
  }, 200);
};

export default showError;
